import { CssBaseline, ThemeProvider } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Store from "./Redux/store";
import Admin from "./scenes/Admin/Admin";
import Appointment from "./scenes/Appointment/Appointment";
import Clinic from "./scenes/Clinic/Clinic";
import Contactus from "./scenes/Contactus/Contactus";
import Designer from "./scenes/Designer/Designer";
import District from "./scenes/District/District";
import Faq from "./scenes/Faqapp/Faq";
import Lab from "./scenes/Lab/Lab";
import Patient from "./scenes/Patients/Patients";
import Plan from "./scenes/Plans/Plan";
import Printer from "./scenes/Printer/Printer";
import Scanner from "./scenes/Scanner/Scanner";
import Bar from "./scenes/bar";
import Calendar from "./scenes/calendar/calendar";
import Contacts from "./scenes/contacts";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Form from "./scenes/form";
import Geography from "./scenes/geography";
import Invoices from "./scenes/invoices";
import Line from "./scenes/line";
import Login from "./scenes/login/Login";
import File from "./scenes/File/File";
import Pie from "./scenes/pie";
import Treatment from "./scenes/Treatment/Treatment";
import { ColorModeContext, useMode } from "./theme";
import PPDDFF from "./scenes/Pdf/pdf";
import Sidebar from "./scenes/global/Sidebar";

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();

  // Define the paths where the Sidebar should be hidden
  const noSidebarPaths = ["/login", "/Treatment-plan/:id"];

  // Determine if the current route matches a path where the Sidebar should be hidden
  const hideSidebar = noSidebarPaths.some(path =>
    location.pathname.startsWith(path.replace(":id", ""))
  );

  return (
    <Provider store={Store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div style={{ display: "flex" }}>
            {/* Conditionally render the Sidebar */}
            {!hideSidebar && <Sidebar />}

            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Admin />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar/:id" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/Plan" element={<Plan />} />
              <Route path="/Faqapp" element={<Faq />} />
              <Route path="/District" element={<District />} />
              <Route path="/Contactus" element={<Contactus />} />
              <Route path="/Appointment" element={<Appointment />} />
              <Route path="/Clinic" element={<Clinic />} />
              <Route path="/Patient" element={<Patient />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Lab" element={<Lab />} />
              <Route path="/Designer" element={<Designer />} />
              <Route path="/Printer" element={<Printer />} />
              <Route path="/Scanner" element={<Scanner />} />
              <Route path="/File/:id" element={<File />} />
              <Route path="/Treatment/:id" element={<Treatment />} />
              <Route path="/Treatment-plan/:id" element={<PPDDFF />} />
            </Routes>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
}

export default App;
