import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import {
  getPrinters,
  deletePrinter,
  updatePrinter,
  addPrinter,
} from "../../Redux/Printer/PrinterActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../Style.css";
import Modal from "react-modal";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import isAuth from "../Utils/isAuth";
import { useNavigate } from "react-router-dom";
import React from "react";

const Printer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  const { printer, loading } = useSelector((state) => state.Printer);

  useEffect(() => {
    dispatch(getPrinters());
  }, [dispatch]);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (printer) {
      setData(printer?.data?.printers);
    }
  }, [printer]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [serial_number, setSerialNumber] = useState("");

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const deletePrinterHandle = (id) => {
    dispatch(deletePrinter(id));
    setModalIsOpen(false);
    setId("");
  };

  const openEditModal = (id, name, serial_number) => {
    setId(id);
    setName(name);
    setSerialNumber(serial_number);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setId("");
    setName("");
    setSerialNumber("");
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setName("");
    setSerialNumber("");
  };

  const openDeleteModal = (id) => {
    setId(id);
    setModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setModalIsOpen(false);
    setId("");
  };

  const addPrinterHandle = () => {
    dispatch(addPrinter(name, serial_number));
    closeAddModal();
  };

  const editPrinterHandle = () => {
    dispatch(updatePrinter(id, name, serial_number));
    closeEditModal();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "serial_number", headerName: "Serial Number", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Fragment>
          <button
            className="actions"

            onClick={() =>
              openEditModal(
                params.row.id,
                params.row.name,
                params.row.serial_number
              )
            }
          >
            Edit
          </button>
          {localStorage.getItem("role") == "superadmin" && <button onClick={() => openDeleteModal(params.row.id)} className="actions1"
          >Delete</button>}
        </Fragment>
      ),
    },
  ];

  return (
    <div className="app">
      {/* <Sidebar /> */}

      <main className="content">
        <Topbar />
        <Box m="20px">
          <Modal
            isOpen={editModalIsOpen}
            onRequestClose={closeEditModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Edit modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}          >
            <form>
              <h2 className="modaltitle">Edit Printer</h2>
              <div className="modal-body modalview">

                <div className="row formRow inputstyle">

                  <h6>Name:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={name}
                    placeholder="Enter Printer Name"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="row formRow inputstyle">

                  <h6>Serial Number:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={serial_number}
                    placeholder="Enter Serial Number"
                    required
                    onChange={(e) => {
                      setSerialNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
            </form>
            <div style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}>
              <button onClick={closeEditModal} className="buttoninmodal">Close</button>
              <button onClick={editPrinterHandle} className="buttoninmodal1"
              >Edit Printer</button>
            </div>
          </Modal>
          <Modal
            isOpen={addModalIsOpen}
            onRequestClose={closeAddModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}          >

            <form>
              <h2 className="modaltitle">Add Printer</h2>
              <div className="modal-body modalview">

                <div className="row formRow inputstyle">

                  <h6>Name:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={name}
                    placeholder="Enter Printer Name"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="row formRow inputstyle">

                  <h6>Serial Number:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={serial_number}
                    placeholder="Enter Serial Number"
                    required
                    onChange={(e) => {
                      setSerialNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
            </form>
            <div style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
            }}>
              <button onClick={closeAddModal} className="buttoninmodal">Close</button>
              <button onClick={addPrinterHandle} className="buttoninmodal1"
              >Add Printer</button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeDeleteModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}          >
            <h2>Delete Printer</h2>
            <h5>Are you sure you want to delete this printer?</h5>
            <div style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}>
              <button onClick={closeDeleteModal} className="buttoninmodal">Close</button>
              <button onClick={() => deletePrinterHandle(id)} className="buttoninmodal1">Delete</button>
            </div>
          </Modal>
          <Header title="Printer" buttonText="Add Printer" onopen={openAddModal} showboth={true} />


          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                zIndex: 0,
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
                zIndex: 0,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                zIndex: 0,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
                zIndex: 0,
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
                zIndex: 0,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
                zIndex: 0,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Printer;
