import React, { useState, useEffect } from "react";
import FullCalendar from '@fullcalendar/react'
import { formatDate } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentbyclinicid,
  getAppointment,
} from "../../Redux/Appointment/AppointmentActions";
import Topbar from "./../global/Topbar";
import Sidebar from "./../global/Sidebar";
import isAuth from "../../scenes/Utils/isAuth";
import { useNavigate } from "react-router-dom";

const Calendar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { Appointment, appointmentbiclinicid, loading } = useSelector(
    (state) => state.Appointment
  );
  const { id } = useParams();
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);
  useEffect(() => {
    if (id == 0) {
      dispatch(getAppointment());
    } else {
      dispatch(getAppointmentbyclinicid(id));
    }
  }, [id]);

  useEffect(() => {
    if (id == 0) {
      setData(Appointment?.data?.appointments);
    } else {
      setData(appointmentbiclinicid?.data?.appointments);
    }
  }, [id, appointmentbiclinicid, Appointment]);

  useEffect(() => {
    // Map through appointments and create events
    const mappedEvents = data?.map((appointment) => ({
      id: appointment.id,
      title: `Appointment with ${appointment.patient.email} with phone number ${appointment.patient.phone_number}`,
      start: appointment.appointment_date,
    }));

    // Set the events in the state
    setEvents(mappedEvents);
  }, [data]); // Trigger the effect when 'data' changes



  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `${selected.event.title}`
      )
    ) {
    }
  };

  return (
    <div className="app">
      {/* <Sidebar isSidebar={isSidebar} /> */}

      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          <Header
            showboth={true}
            title={
              id == 0
                ? "Calendar"
                : `Calendar of ${appointmentbiclinicid?.data?.clinic_name}`
            }
            show={true}
          />

          <Box display="flex" justifyContent="space-between">
            {/* CALENDAR SIDEBAR */}

            <Box
              flex="1 1 20%"
              backgroundColor={colors.primary[400]}
              p="15px"
              borderRadius="4px"
            >
              <Typography variant="h5">Events</Typography>
              <List
                sx={{
                  overflowY: "auto",
                  height: "65vh",
                }}
              >
                {events?.map((event) => (
                  <ListItem
                    key={event.id}
                    sx={{
                      backgroundColor: colors.greenAccent[500],
                      margin: "10px 0",
                      borderRadius: "2px",
                    }}
                  >
                    <ListItemText
                      primary={event.title}
                      secondary={
                        <Typography>
                          {formatDate(event.start, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* CALENDAR */}
            <Box flex="1 1 100%" ml="15px">
              <FullCalendar
                height="75vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                events={events} // Pass the events to the calendar
                eventClick={handleEventClick}
              />
            </Box>
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Calendar;
