import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import {
  getDesigners,
  deleteDesigner,
  updateDesigner,
  addDesigner,
} from "../../Redux/Designer/DesignerActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../Style.css";
import Modal from "react-modal";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import isAuth from "../Utils/isAuth";
import { useNavigate } from "react-router-dom";

const Designer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  const { designer, loading } = useSelector((state) => state.Designer);

  useEffect(() => {
    dispatch(getDesigners());
  }, [dispatch]);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (designer) {
      setData(designer?.data?.designers);
    }
  }, [designer]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const deleteDesignerHandle = (id) => {
    dispatch(deleteDesigner(id));
    setModalIsOpen(false);
    setId("");
  };

  const openEditModal = (id, name, last_name, email, phone_number) => {
    setId(id);
    setName(name);
    setLastName(last_name);
    setEmail(email);
    setPhoneNumber(phone_number);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setId("");
    setName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setName("");
    setLastName("");

    setEmail("");
    setPhoneNumber("");
  };

  const openDeleteModal = (id) => {
    setId(id);
    setModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setModalIsOpen(false);
    setId("");
  };

  const addDesignerHandle = () => {
    dispatch(addDesigner(name, last_name, email, phone_number));

    closeAddModal();
  };

  const editDesignerHandle = () => {
    dispatch(updateDesigner(id, name, last_name, email, phone_number));
    closeEditModal();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    { field: "first_name", headerName: "first_name", flex: 1 },
    { field: "last_name", headerName: "last_name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone_number", headerName: "Phone Number", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Fragment>
          <button
            className="actions"

            onClick={() =>
              openEditModal(
                params.row.id,
                params.row.first_name,
                params.row.last_name,
                params.row.email,
                params.row.phone_number
              )
            }
          >
            Edit
          </button>
          {localStorage.getItem("role") == "superadmin" && <button className="actions1"
            onClick={() => openDeleteModal(params.row.id)}>Delete</button>}
        </Fragment>
      ),
    },
  ];

  return (
    <div className="app">
      {/* <Sidebar /> */}

      <main className="content">
        <Topbar />
        <Box m="20px">
          <Modal
            isOpen={editModalIsOpen}
            onRequestClose={closeEditModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="Edit modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <form>
              <h2 className="modaltitle">Edit Designer</h2>
              <div className="modal-body modalview">

                {/* Name */}
                <div className="row formRow inputstyle">
                  <h6>First Name:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={name}
                    placeholder="Enter the First "
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="row formRow inputstyle">
                  <h6>Last Name:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={last_name}
                    placeholder="Enter the Last Name "
                    required
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div className="row formRow inputstyle">
                  <h6>Email:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={email}
                    placeholder="Enter the Email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                {/* Phone Number */}
                <div className="row formRow inputstyle">
                  <h6>Phone Number:</h6>
                  <input
                    type="text"
                    className="inputt col-md-8 form-control cat"
                    value={phone_number}
                    placeholder="Enter the Phone Number"
                    required
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>



              </div>
            </form>
            <div style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}>

              <button onClick={closeEditModal} className="buttoninmodal"
              >Close</button>
              <button onClick={editDesignerHandle} className="buttoninmodal1"
              >Edit Designer</button>
            </div>
          </Modal>
          <Modal
            isOpen={addModalIsOpen}
            onRequestClose={closeAddModal}

            shouldCloseOnOverlayClick={false}
            contentLabel="Edit modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40%",

                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}          >

            <div>
              <h2 className="modaltitle">Add Deigner</h2>
              <form>
                <div className="modal-body modalview">

                  {/* Name */}
                  <div className="row formRow inputstyle">
                    <h6>First Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={name}
                      placeholder="Enter the First "
                      required
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Last Name:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={last_name}
                      placeholder="Enter the Last Name "
                      required
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="row formRow inputstyle">
                    <h6>Email:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={email}
                      placeholder="Enter the Email"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  {/* Phone Number */}
                  <div className="row formRow inputstyle">
                    <h6>Phone Number:</h6>
                    <input
                      type="text"
                      className="inputt col-md-8 form-control cat"
                      value={phone_number}
                      placeholder="Enter the Phone Number"
                      required
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                  </div>



                </div>
              </form>
            </div>
            <div style={{
              height: "4rem",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}>
              <button onClick={closeAddModal} className="buttoninmodal">Close</button>
              <button onClick={addDesignerHandle} className="buttoninmodal1">Add Designer</button>
            </div>
          </Modal>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeDeleteModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "15rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}          >
            <h2>Delete Designer</h2>
            <h5>Are you sure you want to delete this designer?</h5>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeDeleteModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => deleteDesignerHandle(id)}
                className="buttoninmodal1"
              >
                Delete
              </button>
            </div>
          </Modal>
          <Header title="Designers" buttonText="Add Designer" onopen={openAddModal} showboth={true} />
          <Box m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}>
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Designer;
