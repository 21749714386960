import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import {
  getAppointment,
  assignappointment,
  approveappointment,
} from "../../Redux/Appointment/AppointmentActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import Loading from "../../components/Loading";
import "./../Style.css";
import Modal from "react-modal";
import PopupModal from "../../components/PopupModal";
import { getDistrict } from "../../Redux/District/DistrictActions";
import { getClinic } from "../../Redux/Clinic/ClinicActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import React from "react";
import Topbar from "./../global/Topbar";
import Sidebar from "./../global/Sidebar";
import isAuth from "../../scenes/Utils/isAuth";
import { useNavigate } from "react-router-dom";

const Appointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { Appointment, loading } = useSelector((state) => state.Appointment);
  const { District } = useSelector((state) => state.District);
  const { Clinic } = useSelector((state) => state.Clinic);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/login");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getAppointment());
    dispatch(getDistrict());
  }, []);
  const [district_id, setDistrict_id] = useState("");
  const [showByDistrict, setShowByDistrict] = useState(false);

  useEffect(() => {
    dispatch(getClinic(showByDistrict ? district_id : null));
  }, [district_id, showByDistrict]);
  const [data, setData] = useState([]);


  useEffect(() => {
    if (Appointment && Appointment.data && Array.isArray(Appointment.data.appointments)) {
      const reversedAppointments = [...Appointment.data.appointments].reverse();
      setData(reversedAppointments);
    }
  }, [Appointment]);
  const approveapp = (id) => {
    dispatch(approveappointment(id));
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [id, setId] = useState("");
  const [clinic_id, setclinic_id] = useState("");
  const [appointment_date, setappointment_date] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setclinic_id("");
    setappointment_date("");
    setDistrict_id("");
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("12:00");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };
  const formattedDateTime = () => {
    const formattedDate = selectedDate.toISOString().split("T")[0];
    return `${formattedDate}T${selectedTime}`;
  };
  const date = formattedDateTime();
  const assignapp = () => {
    dispatch(assignappointment(id, clinic_id, date));
    dispatch(getAppointment());
    closeModal();
  };
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (clinic_id && date) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [clinic_id, date]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "Email",
      headerName: "Email",
      valueGetter: (params) => params.row.patient.email,
      width: 200,
    },
    {
      field: "Patient Name",
      headerName: "Patient Name",
      align: "center",
      width: 200, renderCell: (params) => {
        return (
          <div className="itembeside">
            {params.row.patient?.patients.map((item, index) => (
              <React.Fragment key={item.id}>
                <p>
                  {item.first_name} {item.last}
                </p>
                {index < params.row.patient.patients.length - 1 && (
                  <span>--</span>
                )}
              </React.Fragment>
            ))}
          </div>
        );
      },
    },
    {
      field: "Phone Number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.patient.phone_number,
      width: 200,
    },
    {
      field: "Preferred Date",
      headerName: "Preferred Date",
      valueGetter: (params) => params.row.preferred_date,
      width: 200,
    },
    {
      field: "Patient District",
      headerName: "District",
      valueGetter: (params) => params.row.patient.district?.name,
      width: 200,
    },
    {
      field: "Clinic",
      headerName: "Clinic",
      valueGetter: (params) =>
        params.row.clinic?.doctor_name
          ? params.row.clinic?.doctor_name
          : "Not assigned",
      width: 200,
    },
    {
      field: "Clinic phone",
      headerName: "Clinic phone",
      valueGetter: (params) => params.row.clinic?.doctor_phone_number,
      width: 200,
    },

    {
      field: "number_of_people",
      headerName: "number_of_people",
      width: 200, align: "center",
    },
    {
      field: "Approved",
      headerName: "Approved",
      align: "center",

      width: 200, cellClassName: (params) => {
        return params.value === 0 ? "green-cell" : "red-cell";
      },
      renderCell: (params) => {
        return (
          <button
            className={params.row.approved != 0 ? "green-button" : "red-button"}
            onClick={() => {
              approveapp(params.row.id);
            }}
            disabled={
              params.row.clinic_id == null ||
              params.row.appointment_date == null
            }
          >
            {params.row.approved ? "Approved" : "Not Approved"}
          </button>
        );
      },
    },
    { field: "appointment_date", headerName: "appointment_date", width: 200, },

    {
      field: "action",
      headerName: "Action",

      width: 200,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-carousel-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setappointment_date(record.row.appointment_date);
                setDistrict_id(record.row.patient.district_id);
                openModal();
              }}
            >
              Assign
            </button>
          </Fragment>
        );
      },
    },
  ];

  const Cliniccolumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      width: 200,

    },
    {
      field: "Email",
      headerName: "Email",
      valueGetter: (params) => params.row.patient.email,
      width: 300,
      className: "name-column--cell",

    },
    {
      field: "Patient Name",
      headerName: "Patient Name",
      align: "center",
      flex: 1,
      width: 200,

      renderCell: (params) => {
        return (
          <div className="itembeside">
            {params.row.patient?.patients.map((item, index) => (
              <React.Fragment key={item.id}>
                <p>
                  {item.first_name} {item.last}
                </p>
                {index < params.row.patient.patients.length - 1 && (
                  <span>--</span>
                )}
              </React.Fragment>
            ))}
          </div>
        );
      },
    },
    {
      field: "Phone Number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.patient.phone_number,
      flex: 1,
    },
    {
      field: "Patient District",
      headerName: "District",
      valueGetter: (params) => params.row.patient.district?.name,
      flex: 1,
    },


    {
      field: "number_of_people",
      headerName: "number_of_people",
      flex: 1,
      align: "center",
    },


    { field: "appointment_date", headerName: "appointment_date", flex: 1 },


  ];
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div className="app">
      {/* <Sidebar isSidebar={isSidebar} /> */}

      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />

        <Box m="20px">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            contentLabel="delete modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                width: "40rem",
                height: "30rem",
                margin: "0 auto",
                padding: "10px",
                background: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                zIndex: 1000,
              },
            }}
          >
            <div>
              <h2 className="modaltitle">Assign Appointment</h2>
              <form>
                <div className="modal-body modalview">
                  {/* <div className="row formRow inputstyle">
                
              </div> */}
                  {/* Clinic ID */}
                  <div className=" formRow inputstyle">
                    <h6>Clinic :</h6>

                    <select
                      onChange={(e) => setclinic_id(e.target.value)}
                      required
                      defaultValue={clinic_id}
                      className="selectg"
                    >
                      <option value="" disabled className="option">
                        Please select Clinic
                      </option>
                      {Clinic && Clinic.data && Clinic.data.clinics ? (
                        Clinic.data.clinics.map((clinic) => (
                          <option key={clinic.id} value={clinic.id}>
                            {clinic.name}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled className="option">
                          No clinics available
                        </option>
                      )}
                    </select>

                    <label>
                      <input
                        type="checkbox"
                        checked={showByDistrict}
                        onChange={() => setShowByDistrict(!showByDistrict)}
                      />
                      Show clinic according to patient's district
                    </label>
                  </div>

                  {/* District ID */}
                  <div className=" formRow inputstyle">
                    <h6>Date :</h6>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="selectg"
                    />
                  </div>

                  {/* Time Picker */}
                  <div className=" formRow inputstyle">
                    <h6>Time :</h6>
                    <TimePicker
                      value={selectedTime}
                      onChange={handleTimeChange}
                      className="selectg"
                      clockIcon={null}
                      disableClock={true}
                      clearIcon={null}



                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              style={{
                height: "4rem",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button onClick={closeModal} className="buttoninmodal">
                Close
              </button>
              <button
                onClick={() => {
                  assignapp();
                }}
                className="buttoninmodal1"
                // disabled={disable}
                style={{
                  backgroundColor: disable ? "grey" : "red",
                  border: disable ? "1px solid grey" : "1px solid red",
                }}
              >
                Update
              </button>
            </div>
          </Modal>
          <Header title="Appointment" show={true} showboth={true} />
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                zIndex: 0,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            {loading && <Loading />}
            {!loading && data && (
              <DataGrid
                rows={data}
                columns={localStorage.getItem("role") == "clinic" ? Cliniccolumns : columns}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: "16px", // Adjust the font size as needed
                    color: "black",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "bold", // Adjust the font size as needed
                  },
                }}
                checkboxSelection={true}
              />
            )}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Appointment;
